<template>
  <div class="container">
    <div class="title">
      <div class="text">数据领域</div>
      <div class="lineBox">
        <div class="line">
          <span class="lineText">Data Domain</span>
        </div>
      </div>
    </div>
    <div class="content" v-loading="showLoading">
      <div class="dataWrapper" v-for="item in dataDomain" :key="item.index">
        <div @click="linkTo(item)" class="imgBox">
          <img :src="item.imgurl" />
        </div>
        <div @click="linkTo(item)" class="text">{{ item.datatitle }}</div>
      </div>
    </div>
  </div>
</template>
<script>
// const IMGURL = {
//   机构团体: require("../../assets/dataDomainImg/group.png"),
//   民生服务: require("../../assets/dataDomainImg/services.png"),
//   经济建设: require("../../assets/dataDomainImg/build.png"),
//   卫生健康: require("../../assets/dataDomainImg/health.png"),
//   教育科技: require("../../assets/dataDomainImg/education.png"),
//   道路交通: require("../../assets/dataDomainImg/traffic.png"),
//   社会发展: require("../../assets/dataDomainImg/development.png"),
//   文化休闲: require("../../assets/dataDomainImg/culture.png"),
//   资源环境: require("../../assets/dataDomainImg/resource.png"),
//   信用服务: require("../../assets/dataDomainImg/credit.png"),
//   城市建设: require("../../assets/dataDomainImg/city.png"),
//   公共安全: require("../../assets/dataDomainImg/security.png")
// };
export default {
  data() {
    return {
      title: "",
      dataDomain: [
        {
          datatitle: "经济建设"
        },
        {
          datatitle: "资源环境"
        },
        {
          datatitle: "教育科技"
        },
        {
          datatitle: "道路交通"
        },
        {
          datatitle: "社会发展"
        },
        {
          datatitle: "公共安全"
        },
        {
          datatitle: "文化休闲"
        },
        {
          datatitle: "卫生健康"
        },
        {
          datatitle: "民生服务"
        },
        {
          datatitle: "机构团体"
        },
        {
          datatitle: "城市建设"
        },
        {
          datatitle: "信用服务"
        }
      ],
      showLoading: false
    };
  },
  methods: {
    fetchData() {
      this.showLoading = true;
      this.$api.homePage
        .dataDomain({})
        .then(res => {
          this.showLoading = false;
          if (res.code === 200) {
            this.dataDomain = res.data.map(item => {
              return {
                datatitle: item.datatitle,
                imgurl: item.imgurl
              };
            });
          }
        })
        .catch(err => {
          this.showLoading = false;
          console.log(err);
        });
    },
    linkTo(item) {
      this.$router.push({
        path: "/data-source",
        query: { data: item.datatitle }
      });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #fff;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      width: 96px;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #282828;
      line-height: 33px;
    }
    .lineBox {
      width: 280px;
      height: 30px;
      display: flex;
      align-items: center;
      .line {
        width: 180px;
        line-height: 2px;
        border-left: solid 50px #5883f8;
        border-right: solid 50px #5883f8;
        text-align: center;
        .lineText {
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #282828;
          padding: 0 0;
          text-align: center;
        }
      }
    }
  }
  .content {
    margin-left: 96px;
    margin-top: 44px;
    width: 1176px;
    height: 364px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    .dataWrapper {
      width: 100px;
      margin-right: 96px;
      height: 142px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .imgBox {
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        // background-image: linear-gradient(90deg, #9eceff 0%, #56a9ff 100%);
      }
      .text {
        cursor: pointer;
        margin-top: 20px;
        width: 64px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
    }
  }
}
</style>
