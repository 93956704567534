<template>
  <div class="searchContainer">
    <div class="imgBox">
      <img
        style="object-fit: cover;"
        src="../../assets/images/Banner.png"
        width="100%"
        height="580px"
      />
    </div>
    <div class="searchBox">
      <div class="searchContent">
        <el-input
          v-model="searchKeyword"
          class="searchInput"
          placeholder="请输入数据资源关键字"
        >
          <i slot="prefix">
            <img
              style="width:38px;height:33px"
              src="../../assets/images/search-icon1.png"
            />
          </i>
        </el-input>
        <el-button type="primary" style="margin-left:720px;" @click="search"
          >全站搜索</el-button
        >
        <el-button type="primary" v-popover:popover @click="clearHistoryData"
          >高级搜索</el-button
        >
      </div>
      <div class="searchPopover">
        <el-popover
          class="popover"
          ref="popover"
          placement="bottom-end"
          width="1020px"
          trigger="click"
        >
          <template>
            <el-select v-model="resourceType" placeholder="请选择资源类型">
              <el-option
                v-for="item in resourceTypeList"
                :key="item.value"
                :label="item.datatitle"
                :value="item.datatitle"
              >
              </el-option>
            </el-select>
            <el-select v-model="dataDomain" placeholder="请选择数据领域">
              <el-option
                v-for="item in dataDomainList"
                :key="item.value"
                :label="item.datatitle"
                :value="item.datatitle"
              >
              </el-option>
            </el-select>
            <el-select v-model="appscene" placeholder="请选择应用场景">
              <el-option
                v-for="item in appsceneList"
                :key="item.value"
                :label="item.datatitle"
                :value="item.datatitle"
              >
              </el-option>
            </el-select>
            <el-input
              style="width:200px;"
              v-model="keyword"
              placeholder="添加关键字"
            >
            </el-input>
            <el-button
              @click="seniorSearch"
              style="width: 100px;height:40px;padding:0;"
              type="primary"
              ><i
                ><img
                  style="width:31px;height:27px"
                  src="../../assets/images/search-icon2.png"/></i
            ></el-button>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      resourceTypeList: [],
      dataDomainList: [],
      appsceneList: [],
      searchKeyword: "",
      keyword: "",
      resourceType: "",
      dataDomain: "",
      appscene: "",
      pageNum: "1",
      list: [],
      loading: false
    };
  },
  mounted() {
    // debugger
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$api.homePage.dataMenu().then(res => {
        console.log(res);
        this.resourceTypeList = res.data.type.value;
        this.dataDomainList = res.data.data.value;
        this.appsceneList = res.data.app.value;
      });
    },
    search() {
      this.$router.push({
        path: "/data-source",
        query: { keyword: this.searchKeyword }
      });
    },
    seniorSearch() {
      this.$router.push({
        path: "/data-source",
        query: {
          type: this.resourceType,
          area: this.dataDomain,
          scene: this.appscene,
          multiple: true,
          keyword: this.keyword
        }
      });
    },
    clearHistoryData() {
      this.keyword = "";
      this.resourceType = "";
      this.dataDomain = "";
      this.appscene = "";
      this.input = "";
    }
  }
};
</script>
<style lang="scss" scoped>
.el-button + .el-button {
  margin-left: 20px;
}
.el-button {
  width: 140px;
  height: 50px;
  background: #5883f8;
  border-radius: 4px;
  border: none;
  font-size: 18px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ffffff;
}
.el-input {
  width: 700px;
}

.el-select {
  width: 200px;
  // height: 40px;
  .el-input__inner {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #989898;
  }
}

.searchContainer {
  width: 100%;
  height: 580px;
  position: relative;
  .searchBox {
    position: absolute;
    top: 420px;
    left: calc(50% - 510px);
    margin: 0 auto;
    .searchContent {
      width: 1020px;
      height: 50px;
      position: relative;
      .searchInput {
        position: absolute;
      }
    }
  }
}
</style>
