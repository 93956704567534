<template>
  <div class="container">
    <div class="title">
      <div class="text">应用场景</div>
      <div class="lineBox">
        <div class="line">
          <span class="lineText">Application Scenarios</span>
        </div>
      </div>
    </div>
    <div class="content">
      <img :src="imgUrl" />
      <div class="lifeService" v-if="data" @click="linkTo(data[5])">
        {{ data[5].datatitle }}
      </div>
      <div class="wechat"></div>
      <div class="contacts"></div>
      <div class="government" v-if="data" @click="linkTo(data[7])">
        {{ data[7].datatitle }}
      </div>
      <div class="marriage" @click="linkTo(data[6])" v-if="data">
        {{ data[6].datatitle }}
      </div>
      <div class="traffic" v-if="data" @click="linkTo(data[0])">
        {{ data[0].datatitle }}
      </div>
      <div class="hospital" v-if="data" @click="linkTo(data[1])">
        {{ data[1].datatitle }}
      </div>
      <div class="socialSecurity" v-if="data" @click="linkTo(data[8])">
        {{ data[8].datatitle }}
      </div>
      <div class="education" v-if="data" @click="linkTo(data[3])">
        {{ data[3].datatitle }}
      </div>
      <div class="employment" v-if="data" @click="linkTo(data[4])">
        {{ data[4].datatitle }}
      </div>
      <div class="personal"></div>
      <div class="security" v-if="data" @click="linkTo(data[2])">
        {{ data[2].datatitle }}
      </div>
    </div>
    <div class="backgroundShape1"></div>
    <div class="backgroundShape2"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgUrl: require("../../assets/images/application.png"),
      data: null
    };
  },
  methods: {
    fetchData() {
      this.$api.homePage
        .appscene()
        .then(res => {
          if (res.code === 200) {
            console.log(res);
            this.data = res.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    linkTo(item) {
      this.$router.push({
        path: "/data-source",
        query: { app: item.datatitle }
      });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 829px;
  background: #f4f6fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .title {
    margin-top: 58px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      width: 96px;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #282828;
      line-height: 33px;
    }
    .lineBox {
      width: 280px;
      height: 30px;
      display: flex;
      align-items: center;
      .line {
        width: 180px;
        line-height: 2px;
        border-left: solid 50px #5883f8;
        border-right: solid 50px #5883f8;
        text-align: center;
        .lineText {
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #282828;
          padding: 0 0;
          text-align: center;
        }
      }
    }
  }
  .content {
    position: relative;
    img {
      object-fit: cover;
    }
    .lifeService {
      opacity: 0;
      cursor: pointer;
      width: 93px;
      height: 93px;
      border-radius: 50%;
      position: absolute;
      top: 187px;
      left: 26px;
      // background-color: pink;
    }
    .wechat {
      cursor: pointer;
      width: 79px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      top: 146px;
      left: 225px;
      // background-color: pink;
    }
    .contacts {
      cursor: pointer;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      position: absolute;
      top: 192px;
      left: 448px;
      // background-color: pink;
    }
    .government {
      opacity: 0;
      cursor: pointer;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      position: absolute;
      top: 20px;
      left: 310px;
      // background-color: pink;
    }
    .marriage {
      opacity: 0;
      cursor: pointer;
      width: 115.5px;
      height: 115.5px;
      border-radius: 50%;
      position: absolute;
      top: 319px;
      left: 240px;
      // background-color: pink;
    }
    .traffic {
      opacity: 0;
      cursor: pointer;
      width: 115.5px;
      height: 115.5px;
      border-radius: 50%;
      position: absolute;
      top: 397px;
      left: 440px;
      // background-color: pink;
    }
    .hospital {
      opacity: 0;
      cursor: pointer;
      width: 122px;
      height: 122px;
      border-radius: 50%;
      position: absolute;
      top: 104px;
      left: 587px;
      // background-color: pink;
    }
    .socialSecurity {
      opacity: 0;
      cursor: pointer;
      width: 233px;
      height: 232px;
      border-radius: 50%;
      position: absolute;
      top: 234px;
      left: 586px;
      // background-color: pink;
    }
    .education {
      opacity: 0;
      cursor: pointer;
      width: 143px;
      height: 143px;
      border-radius: 50%;
      position: absolute;
      top: 106px;
      left: 796px;
      // background-color: pink;
    }
    .employment {
      opacity: 0;
      cursor: pointer;
      width: 135px;
      height: 135px;
      border-radius: 50%;
      position: absolute;
      top: 316px;
      left: 882px;
      // background-color: pink;
    }
    .personal {
      cursor: pointer;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      position: absolute;
      top: 94px;
      left: 1080px;
      // background-color: pink;
    }
    .security {
      opacity: 0;
      cursor: pointer;
      width: 121px;
      height: 121px;
      border-radius: 50%;
      position: absolute;
      top: 427px;
      left: 1042px;
      // background-color: pink;
    }
  }
  .backgroundShape1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 243px;
    height: 287px;
    background: #ecf1f9;
    border-radius: 0 243px 0 0/0 287px 0 0;
  }
  .backgroundShape2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 337px;
    height: 287px;
    background: #ecf1f9;
    border-radius: 0 0 0 337px/0 0 0 287px;
  }
}
</style>
