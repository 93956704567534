<template>
  <layout>
    <search></search>
    <data-domain></data-domain>
    <application-scenarios></application-scenarios>
    <recent-news></recent-news>
  </layout>
</template>

<script>
import Layout from "@/views/Layouts";
import Search from "./search";
import DataDomain from "./data-domain";
import ApplicationScenarios from "./application-scenarios";
import RecentNews from "./recent-news";

export default {
  name: "Home",
  components: {
    Layout,
    Search,
    DataDomain,
    ApplicationScenarios,
    RecentNews
  }
};
</script>
<style lang="scss" scoped></style>
