<template>
  <div class="container">
    <div class="title">
      <div class="text">近期要闻</div>
      <div class="lineBox">
        <div class="line">
          <span class="lineText">Recent News</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="img" @click="linkTo">
        <img
          src="../../assets/images/news.png"
          style="width:360px;height:460px"
        />
        <div class="imgTextBox">
          <span class="imgContent">
            第14期“嘉华大数据讲坛|
            大数据驱动的环境事件研究——非法倾倒垃圾为例”成功举办
          </span>
        </div>
      </div>
      <div class="newsListLeft">
        <div
          class="news"
          v-for="(item, index) in newsListLeft"
          :key="index"
          @click="leftlinkTo(index)"
        >
          <div class="newsTitle">
            {{ item.newsTitle }}
          </div>
          <div class="newsContent">
            {{ item.newsOverview }}
          </div>
          <div class="date">
            <span class="day">{{ item.newsDateDay }}</span>
            <div class="yearAndMonth">
              <span>{{ item.YearAndMouth }}</span>
              <div class="arrowIcon">
                <img :src="item.arrowIconUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="newsListRight">
        <div
          class="news"
          v-for="(item, index) in newsListRight"
          :key="index"
          @click="rightlinkTo(index)"
        >
          <div class="date">
            <span class="day">{{ item.newsDateDay }}</span>
            <span class="yearAndMonth">{{ item.YearAndMouth }}</span>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="newsTitle">
            <span class="newsContent">{{ item.newsTitle }}</span>
            <div class="arrowIcon">
              <img :src="item.arrowIconUrl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newsListLeft: [
        {
          newsTitle:
            "第14期“嘉华大数据讲坛| 大数据驱动的环境事件研究——非法倾倒垃圾为例”成功举办",
          newsOverview:
            "2020年11月19日，第14期“嘉华大数据讲坛”在上海交大徐汇校区新建楼238会议室成功举办。讲坛的主题为“大数据驱动的环境事件研究——非法倾倒垃圾为例”，受邀嘉宾为上海交通大学国际与公共事务学院教授樊博。本次讲坛采用“线上和线下相结合”的方式举行，由上海交通大学中国城市治理研究院专职研究员俞俊利主持，上海交通大学环境科学与工程学院楼紫阳担任评论人。本次讲坛作为中国城市治理研究院嘉华大数据中心“大数据城市治理”专项课题的成果展示活动之一，受到了广泛的关注。来自中山大学、华中科技大学、湘潭大学以及上海交通大学校内的安泰经济与管理学院、国际与公共事务学院、中国城市治理研究院20余名校内外人员参加。",
          newsDateDay: "25",
          YearAndMouth: "2020/12",
          arrowIconUrl: require("../../assets/images/arrow.png"),
          url:
            "http://47.93.21.163:9010/shangchuan/20201125-%E3%80%90%E5%98%89%E5%8D%8E%E5%A4%A7%E6%95%B0%E6%8D%AE%E8%AE%B2%E5%9D%9B%E3%80%91%E7%AC%AC14%E6%9C%9F%E6%96%B0%E9%97%BB%E7%A8%BF%EF%BC%88%E6%8F%90%E4%BA%A4%E7%89%88%EF%BC%89_20201222174417.pdf"
        },
        {
          newsTitle: "“如何构建数据可视化场景？”专题报告会成功举办",
          newsOverview:
            "2020年11月11日，数据应用场景专题会在上海交通大学徐汇校区新建楼238会议室成功举办。讲坛的主题为“如何构建数据可视化场景？”，受邀嘉宾是上海熠源数据科技有限公司联合创始人黄惠寅。本次讲坛采用“线上和线下相结合”的方式举行，由上海交通大学中国城市治理研究院副研究员谷晓坤主持。本次专题会旨在促进大数据相关领域研究成果的可视化和可视化分析，受到了广泛的关注。有来自上海交通大学校内的致远学院、船舶海洋与建筑工程学院、环境科学与工程学院、安泰经济与管理学院、国际与公共事务学院、中国城市治理研究院等20余名校内人员参加。",
          newsDateDay: "13",
          YearAndMouth: "2020/11",
          arrowIconUrl: require("../../assets/images/arrow.png"),
          url:
            "http://47.93.21.163:9010/shangchuan/20201113-%E3%80%90%E5%98%89%E5%8D%8E%E5%A4%A7%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%BF%83%E3%80%91%E6%95%B0%E6%8D%AE%E5%BA%94%E7%94%A8%E5%9C%BA%E6%99%AF%E4%B8%93%E9%A2%98%E4%BC%9A%E6%96%B0%E9%97%BB%E7%A8%BF%EF%BC%88%E6%8F%90%E4%BA%A4%E7%89%88%EF%BC%89_20201222174525.pdf"
        }
      ],
      newsListRight: [
        {
          newsTitle:
            "第13期“嘉华大数据讲坛| 基于大数据的上海市生活垃圾管理研究”成功举办",
          newsDateDay: "29",
          YearAndMouth: "2020/10",
          arrowIconUrl: require("../../assets/images/arrow.png"),
          url:
            "http://47.93.21.163:9010/shangchuan/20201029-%E3%80%90%E5%98%89%E5%8D%8E%E5%A4%A7%E6%95%B0%E6%8D%AE%E8%AE%B2%E5%9D%9B%E3%80%91%E7%AC%AC13%E6%9C%9F%E6%96%B0%E9%97%BB%E7%A8%BF%EF%BC%88%E6%8F%90%E4%BA%A4%E7%89%88%EF%BC%89_20201222174548.pdf"
        },
        {
          newsTitle:
            "中国城市治理研究院嘉华大数据中心等一行赴浦东新区高桥镇南塘村调研",
          newsDateDay: "15",
          YearAndMouth: "2020/7",
          arrowIconUrl: require("../../assets/images/arrow.png"),
          url:
            "http://47.93.21.163:9010/shangchuan/20200715-%E4%B8%AD%E5%9B%BD%E5%9F%8E%E5%B8%82%E6%B2%BB%E7%90%86%E7%A0%94%E7%A9%B6%E9%99%A2%E5%98%89%E5%8D%8E%E5%A4%A7%E6%95%B0%E6%8D%AE%E4%B8%AD%E5%BF%83%E7%AD%89%E4%B8%80%E8%A1%8C%E8%B5%B4%E6%B5%A6%E4%B8%9C%E6%96%B0%E5%8C%BA%E9%AB%98%E6%A1%A5%E9%95%87%E5%8D%97%E5%A1%98%E6%9D%91%E8%B0%83%E7%A0%94%EF%BC%88%E6%96%B0%E9%97%BB%E7%A8%BF%EF%BC%89_20201222174558.pdf"
        },
        {
          newsTitle:
            "第12期“嘉华大数据讲坛| 基于大数据理论的蓝天保卫战：技术体系及案例分析”成功举办",
          newsDateDay: "10",
          YearAndMouth: "2019/12",
          arrowIconUrl: require("../../assets/images/arrow.png"),
          url:
            "http://47.93.21.163:9010/shangchuan/20191210-%E3%80%90%E5%98%89%E5%8D%8E%E5%A4%A7%E6%95%B0%E6%8D%AE%E8%AE%B2%E5%9D%9B%E3%80%91%E7%AC%AC12%E6%9C%9F%E6%96%B0%E9%97%BB%E7%A8%BF%EF%BC%88%E6%8F%90%E4%BA%A4%E7%89%88%EF%BC%89_20201222174609.pdf"
        }
      ]
    };
  },
  methods: {
    linkTo() {
      window.open(this.newsListLeft[0].url, "_blank");
    },
    leftlinkTo(index) {
      window.open(this.newsListLeft[index].url, "_blank");
    },
    rightlinkTo(index) {
      window.open(this.newsListRight[index].url, "_blank");
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 700px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-top: 53px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      width: 96px;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #282828;
      line-height: 33px;
    }
    .lineBox {
      width: 280px;
      height: 30px;
      display: flex;
      align-items: center;
      .line {
        width: 180px;
        line-height: 2px;
        border-left: solid 50px #5883f8;
        border-right: solid 50px #5883f8;
        text-align: center;
        .lineText {
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #282828;
          padding: 0 0;
          text-align: center;
        }
      }
    }
  }
  .content {
    margin-top: 20px;
    width: 1120px;
    height: 460px;
    display: flex;
    justify-content: space-between;
    .img {
      cursor: pointer;
      position: relative;
      width: 360px;
      height: 100%;
      // background-image: linear-gradient(90deg, #9eceff 0%, #56a9ff 100%);
      .imgTextBox {
        position: absolute;
        bottom: 0;
        width: 360px;
        height: 90px;
        background: rgba(51, 51, 51, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        .imgContent {
          width: 314px;
          height: 60px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 30px;
        }
      }
    }
    .newsListLeft {
      width: 370px;
      height: 100%;
      .news {
        cursor: pointer;
        margin-bottom: 30px;
        .newsTitle {
          padding: 19px 21px 8px 22px;
          width: 327px;
          height: 50px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 25px;
        }
        .newsContent {
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          cursor: pointer;
          padding: 0 28px 0px 20px;
          margin-bottom: 6px;
          width: 322px;
          // height: 66px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #656565;
          line-height: 22px;
        }
        .date {
          margin-left: 20px;
          display: flex;
          flex-direction: column;

          .day {
            width: 43px;
            height: 41px;
            font-size: 36px;
            font-family: FranklinGothic-Medium, FranklinGothic;
            font-weight: 500;
            color: #656565;
            line-height: 41px;
            border-bottom-width: 46px;
            border-bottom: 1px solid #ccc;
          }
          .yearAndMonth {
            display: flex;
            width: 48px;
            height: 14px;
            font-size: 12px;
            font-family: FranklinGothic-Medium, FranklinGothic;
            font-weight: 500;
            color: #656565;
            line-height: 14px;
            span {
              margin-top: 2px;
            }
            .arrowIcon {
              cursor: pointer;
              margin-left: 258px;
            }
          }
        }
      }
    }
    .newsListRight {
      width: 370px;
      height: 100%;
      .news {
        cursor: pointer;
        display: flex;
        padding-top: 27px;
        padding-bottom: 40px;
        .date {
          padding-top: 19px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          .day {
            width: 43px;
            height: 41px;
            font-size: 36px;
            font-family: FranklinGothic-Medium, FranklinGothic;
            font-weight: 500;
            color: #656565;
            line-height: 41px;
            border-bottom-width: 46px;
            border-bottom: 1px solid #ccc;
          }
          .yearAndMonth {
            margin-top: 2px;
            display: flex;
            width: 48px;
            height: 14px;
            font-size: 12px;
            font-family: FranklinGothic-Medium, FranklinGothic;
            font-weight: 500;
            color: #656565;
            line-height: 14px;
          }
        }
        .newsTitle {
          .newsContent {
            width: 247px;
            height: 50px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 25px;
            padding-bottom: 16px;
          }
          .arrowIcon {
            cursor: pointer;
            text-align: center;
            margin-top: 16px;
            width: 24px;
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
